import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import analytics from '../../utils/analytics';

type StyledTabsProps = {
    children?: React.ReactNode;
    value: number|false;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }
type StyledTabProps = {
  label: string;
}
export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#F3005A',
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  marginLeft: theme.spacing(1),
}));

export const mobileIcons = (tabValue:string):{[key:string]:React.ReactElement} => {
  const buttonStyle = (value:string) => (
    {
      color: tabValue === value ? '#F3005A' : undefined,
      paddingBottom: '6px',
      fontSize: '22px',
    }
  );
  return (
    {
      'Tu crédito': <CreditCardIcon
        style={buttonStyle('Tu crédito')}
      />,
      Referidos: <StarsRoundedIcon
        style={buttonStyle('Referidos')}
      />,
      Inversiones: <BarChartIcon
        style={buttonStyle('Inversiones')}
      />,
      Pagos: <AccountBalanceIcon
        style={buttonStyle('Pagos')}
      />,
      'Guia.Me': <SummarizeIcon
        style={buttonStyle('Guia.Me')}
      />,
    });
};

export const totalTabs = ['Tu crédito', 'Referidos', 'Inversiones', 'Guia.Me', 'Pagos', 'Cerrar sesión'];
export const onlyInvestTabs = ['Inversiones', 'Guia.Me', 'Pagos', 'Cerrar sesión'];
export const onlyLoanTabs = ['Tu crédito', 'Referidos', 'Guia.Me', 'Pagos', 'Cerrar sesión'];
export const onlyProductsTab = ['Guia.Me', 'Pagos', 'Cerrar sesión'];

export function logOut() {
  analytics.track('Cerrar sesión');
  analytics.reset();
  analytics.identify(null, { isAnonymous: true });
  sessionStorage.removeItem('__react_session__');
  window.location.href = '/account/login';
}
