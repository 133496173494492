import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import ClaveUnicaDialog from '../ClaveUnicaDialog';
import ClaveUnicaErrorDialog from '../ClaveUnicaDialog/errorDialog';
import analytics from '../../utils/analytics';

interface ClaveUnicaFormInterface<T>{
  idNumber: string,
  origin: string,
  token: string,
  pageCall: {category: string, name: string},
  callback: (payload: T) => void,
  endpoint: (params: ClaveUnicaEndpointProps) => Promise<T>,
  firstMessage?: React.ReactElement|null,
  secondMessage?: React.ReactElement|null,
  showDisclaimer?: boolean,
  button?: React.ReactElement|null,
  subtitle?: string,
  partner: string
}

export type ClaveUnicaEndpointProps = {
  token: string,
  password: string,
}

export default function ClaveUnicaForm<T>(props: ClaveUnicaFormInterface<T>) {
  const {
    token,
    endpoint,
    idNumber,
    origin,
    callback,
    firstMessage,
    secondMessage,
    showDisclaimer,
    button,
    subtitle,
    partner,
    pageCall,
  } = props;
  const theme = useTheme();
  const [isPasswordError, setIsPasswordError] = React.useState(false);
  const [isBlockedPasswordError, setIsBlockedPasswordError] = React.useState(false);
  const [isDataExtractionError, setIsDataExtractionError] = React.useState(false);
  const [isMaintenanceError, setIsMaintenanceError] = React.useState(false);
  const [isAFCBlockedPasswordError, setIsAFCBlockedPasswordError] = React.useState(false);
  const [isNotSubscribedError, setIsNotSubscribedError] = React.useState(false);
  const [isConnectionError, setIsConnectionError] = React.useState(false);
  const [passwordNumTries, setPasswordNumTries] = React.useState(0);

  const [triggerSubmit, setTriggerSubmit] = React.useState(false);
  const retryCUSubmit = () => {
    setTriggerSubmit(true);
    setTimeout(() => setTriggerSubmit(false), 1500);
  };

  const getClaveUnicaData = async (password: string) => {
    setIsPasswordError(false);
    setIsBlockedPasswordError(false);
    setIsDataExtractionError(false);
    let res;
    try {
      res = await endpoint({ token, password });
    } catch (e) {
      const category = `${pageCall.category.split('-')[0].trim()} - ERROR CU`;
      if (e === 402) {
        setPasswordNumTries((prevState) => prevState + 1);
        analytics.page(category, 'CU INCORRECTA');
        return setIsPasswordError(true);
      }
      if (e === 422) {
        analytics.page(category, 'CU BLOQUEADA');
        return setIsBlockedPasswordError(true);
      }
      if (e === 423) {
        analytics.page(category, 'CONTRASEÑA AFC BLOQUEADA');
        return setIsAFCBlockedPasswordError(true);
      }
      if (e === 504) {
        analytics.page(category, 'SERVICIO CONSULTADO EN MANTENCION');
        return setIsMaintenanceError(true);
      }
      if (e === 505) {
        analytics.page(category, 'ERROR DE CONEXION');
        return setIsConnectionError(true);
      }
      if (e === 506) {
        analytics.page(category, 'USUARIO NO INSCRITO EN SERVICIO');
        return setIsNotSubscribedError(true);
      }
      setPasswordNumTries((prevState) => prevState + 1);
      analytics.page(category, 'ERROR DE EXTRACIÓN DE DATOS');
      return setIsDataExtractionError(true);
    }
    return callback(res);
  };

  return (
    <>
      { firstMessage }
      { secondMessage }
      <div>
        {showDisclaimer
        && (
        <Typography
          align="center"
          gutterBottom
          fontStyle="italic"
          style={{ color: theme.customPalette.tertiary.main, marginTop: '20px' }}
          variant="body2"
        >
          Banca.Me no guardará tus credenciales
        </Typography>
        )}
        <ClaveUnicaDialog
          rut={idNumber}
          action={(password) => getClaveUnicaData(password)}
          maximumTries={passwordNumTries > 2
              || isAFCBlockedPasswordError
              || isNotSubscribedError
              || isMaintenanceError}
          triggerSubmit={triggerSubmit}
          button={button as React.ReactElement|null}
          subtitle={subtitle as string}
          origin={origin}
        />
      </div>
      <ClaveUnicaErrorDialog
        errors={{
          isPasswordError,
          isDataExtractionError,
          isBlockedPasswordError,
          isMaintenanceError,
          isAFCBlockedPasswordError,
          isNotSubscribedError,
          isConnectionError,
        }}
        numTries={passwordNumTries}
        partner={partner}
        retryCUSubmit={retryCUSubmit}
      />
    </>
  );
}

ClaveUnicaForm.defaultProps = {
  firstMessage: null,
  secondMessage: null,
  showDisclaimer: true,
  button:
  <Button
    data-pw="cu-open-dialog-button"
    variant="contained"
  >
    Ingresar ClaveÚnica
  </Button>,
  subtitle: 'Necesitamos que ingreses tu Clave Única para verificar tus datos.',
};
