import React from 'react';
import {
  Fade,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import avatarSmiling from '../../../../../../assets/avatar_smiling.svg';
import AgentContactButton from '../../../../../../components/AgentContactButton';
import type { PLRFormProps } from '../../../../../../types/preloan';
import {
  getContactMessage, firstMessageSwitch,
  secondMessageSwitch, getRedirectUrl,
} from './components/messageSwitches';
import type { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import analytics from '../../../../../../utils/analytics';
import { getOriginButtonLabel } from '../../formLogic';

export default function LastStepForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const { formRef, formSchema, handleEnableLoading } = props;
  React.useEffect(() => handleEnableLoading(false), []);
  React.useEffect(() => {
    if (formSchema.step === 'READY_FOR_LOAN') {
      analytics.page(formSchema.isRedirect
        ? 'PLR - REDIRECCIÓN RUT'
        : 'PLR - RESULTADO', 'ESPERANDO APROBACIÓN MEDIANTE BACKOFFICE');
    }
    if (formSchema.step === 'COMPANY_APROVE') {
      analytics.page(formSchema.isRedirect
        ? 'PLR - REDIRECCIÓN RUT'
        : 'PLR - RESULTADO', 'ESPERANDO APROBACIÓN DEL EMPLEADOR');
    }
  }, []);
  React.useEffect(
    () => {
      if (formSchema.origin === 'goplaceit') {
        window.location.href = getRedirectUrl(
          formSchema.partnerConfigurations,
          true,
          formSchema.rut,
        );
      }
    },
    [],
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={(values) => {
        analytics.track('Click Boton', {
          initiator: 'usuario',
          text: getOriginButtonLabel(values.origin),
        });
        setTimeout(() => {
          window.location.href = getRedirectUrl(values.partnerConfigurations, true, formSchema.rut);
        }, 100);
      }}
    >
      {({
        values,
      }) => (
        <Fade
          in
          {...({ timeout: 1000 })}
        >
          <Form>
            <h3
              data-pw="preloan-last-step-title"
            >
              ¡Gracias por tu tiempo!

            </h3>
            <object data={avatarSmiling} width="60px" aria-labelledby="AvatarSmiling" />
            <Typography
              style={{ marginTop: 10 }}
              gutterBottom
              variant="body1"
              align="center"
              component="div"
            >
              {firstMessageSwitch(values.step, formSchema.preApprove, formSchema.origin)}
            </Typography>
            <Typography
              style={{ marginTop: 20 }}
              variant="caption"
              align="center"
              component="div"
              gutterBottom
            >
              {secondMessageSwitch(values.step)}
              {' '}
              {getContactMessage(formSchema.partnerConfigurations)}
            </Typography>
            <br />
            {!formSchema.partnerProducts.some((product) => product === 'mortgage')
            && (
            <AgentContactButton
              size="small"
              hide={formSchema.partner !== 'Banca.Me'}
            />
            )}
          </Form>
        </Fade>
      )}
    </Formik>
  );
}
