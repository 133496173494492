import React from 'react';
import {
  Divider,
  useTheme,
} from '@mui/material';
import { Formik, Form } from 'formik';
import type { PLRFormProps } from '../../../../../../../types/preloan';
import { patchPLRStep } from '../../../../../../../queries/preloan/v2';
import { postSimulation } from '../../../../../../../queries/preloan';
import LoanConditionsDialog from './LoanConditionsDialog';
import LoanSimulator from './LoanSimulator';
import LoanProposal from './LoanProposal';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { AmountMarksType, InsuranceType } from '../../../../../../../components/Simulator/types';
import { getCombinationsFromArray } from '../../../../../../../utils/installmentCalculator';
import { partnerErrorRedirectURL, preLoanRequestStep } from '../../../formLogic';
import InsurancesSelector from '../../../../../../../components/InsurancesSelector';
import analytics from '../../../../../../../utils/analytics';

export default function DppOrPatForm
<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleNext,
    handleCurrentData,
    handleStep,
  } = props;
  const theme = useTheme();
  const [showSimulator, setShowSimulator] = React.useState(false);

  const [recommendedAmount, setRecommendedAmount] = React.useState({
    amount: 0, periods: 0, installment: 0, interestRate: 0,
  });

  const [triggerAlert, setTriggerAlert] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loadingSimulation, setLoadingSimulation] = React.useState(true);
  const [calculatedMarksObject, setCalculatedMarksObject] = React.useState<any>(undefined);
  const [selectedAmountMarks,
    setSelectedAmountMarks] = React.useState<AmountMarksType| undefined>(undefined);

  const handleSelectedAmountMarks = (mark: string) => {
    setSelectedAmountMarks(calculatedMarksObject[mark]);
  };
  const [checkedInsurances, setCheckedInsurances] = React.useState<{[key:InsuranceType['type']]: boolean}>({});

  const insurancesArray = formSchema.loanParams.insurances;

  const handleSetCheckedInsurances = (checkedIns: {[key:InsuranceType['type']]: boolean}) => {
    setCheckedInsurances(checkedIns);
  };

  const insuranceKeys = Array.from(new Set(insurancesArray.map((ins) => ins.type)));
  const insuranceCombinations = getCombinationsFromArray(insuranceKeys);

  insuranceCombinations.push(['none']);

  const marksObject: {[key: string]: AmountMarksType|undefined} = { none: undefined };

  insuranceCombinations.forEach((insuranceComb) => {
    marksObject[insuranceComb.toString()] = undefined;
  });
  const getInsuranceRatesByPeriod = (key: string) => {
    const ratesByPeriod: {[key:number]: number} = { 0: 0 };
    insurancesArray.forEach((insurance) => {
      if (insurance.type === key) {
        if (!insurance.minPeriods && !insurance.maxPeriods) {
          ratesByPeriod[0] = insurance.premiumMultiplier;
        } else {
          let i = insurance.minPeriods;
          for (i; i <= insurance.maxPeriods; i += 1) {
            ratesByPeriod[i] = insurance.premiumMultiplier;
          }
        }
      }
    });
    return ratesByPeriod;
  };

  const insuranceWorkers = insuranceCombinations.map((insuranceComb) => ({
    value: insuranceComb,
    rate: insuranceComb.map((key) => (key === 'none'
      ? { 0: 0 }
      : getInsuranceRatesByPeriod(key))).reduce((res, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        res[+key] = (res[+key] || 0) + value;
      });
      return res;
    }, {} as {[key: number]: number}),
    worker: React.useMemo(
      () => new Worker(new URL('worker.ts', import.meta.url)),
      [],
    ),
  }));

  const triggerWorkers = () => {
    insuranceWorkers.forEach((insuranceWorker) => {
      insuranceWorker.worker.postMessage({
        value: insuranceWorker.value,
        insuranceRates: insuranceWorker.rate,
        loanParams: formSchema.loanParams,
      });
      insuranceWorker.worker.addEventListener('message', (msg: MessageEvent<{value: string,
        result: AmountMarksType}>) => {
        marksObject[msg.data.value.toString()] = msg.data.result;
        if (!Object.values(marksObject).some((uInput) => uInput === undefined)) {
          setSelectedAmountMarks(marksObject[insuranceCombinations[0].toString()]);
          setCalculatedMarksObject(marksObject);
        }
      }, { once: true });
    });
  };

  const handleShowSimulator = () => {
    setShowSimulator((o: boolean) => !o);
  };
  const handleLoadingSimulation = (isLoading: boolean) => {
    setLoadingSimulation(isLoading);
  };

  const requestLoan = (params: {
    amount: number,
    interestRate: number,
    periods: number,
    installment: number,
    totalCost: number,
    monthlyCAE: number,
    CAE: number,
    originCost: number,
    taxes: number,
    insuranceCost: number,
    isInitialState: boolean,
  }) => {
    const {
      amount, interestRate, periods,
      installment, totalCost, monthlyCAE,
      CAE, originCost, taxes, insuranceCost,
      isInitialState,
    } = params;
    const loanData = {
      amount,
      interestRate,
      periods,
      installment,
      totalCost,
      monthlyCAE,
      CAE,
      originCost,
      taxes,
      insuranceCost,
    };
    if (isInitialState) {
      analytics.page(
        formSchema.isRedirect
          ? 'PLR - REDIRECCIÓN RUT' : 'PLR - RESULTADO',
        'OFERTA INICIAL',
        {
          productType: formSchema.loanParams.productType,
          amount,
          term: periods,
          rate: interestRate,
          installmentAmount: installment,
          insurances: insuranceKeys,
        },
      );
    }
    handleCurrentData({
      ...formSchema,
      ...isInitialState
        ? { requestLoanDataInitialState: loanData, requestLoanData: loanData }
        : {
          requestLoanData: {
            ...loanData,
            checkedInsurances: Object.entries(checkedInsurances)
              .filter((entries) => entries[1])
              .map(([key]) => key),
          },
        },
      isRedirect: false,
    });
    setRecommendedAmount({
      amount, periods, installment, interestRate,
    });
    if (((formSchema.requestedAmount
      && (+formSchema.loanParams.riskEngineMaxAmount < formSchema.requestedAmount))
      || (formSchema.requestedPeriods && (formSchema.requestedPeriods !== periods))
      || (formSchema.requestedInstallment && (formSchema.requestedInstallment !== installment)))
      && !showSimulator) {
      setTriggerAlert(true);
    }
  };
  const archivePLR = async () => {
    const rejectReason = 'No tenemos un producto que se adapte a tus capacidades de pago.';
    await patchPLRStep('ARCHIVED_FAIL', [rejectReason], formSchema.PLRtoken);
    handleCurrentData({
      ...formSchema,
      adminComment: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
    });
    handleStep(preLoanRequestStep.ARCHIVED_FAIL);
  };

  React.useEffect(
    () => {
      if (!selectedAmountMarks && insuranceWorkers?.length > 0) {
        triggerWorkers();
      }
    },
    [insuranceWorkers],
  );

  React.useEffect(() => {
    if (!loadingSimulation) { handleEnableLoading(false); }
  }, [loadingSimulation]);

  React.useEffect(() => {
    if (!showSimulator) {
      handleLoadingSimulation(true);
      setSelectedAmountMarks(marksObject[insuranceCombinations[0].toString()]);
      const insuranceCheckBoxes: {[key:string]: boolean} = {};
      insurancesArray.forEach((insurance) => {
        insuranceCheckBoxes[insurance.type] = true;
      });
      setCheckedInsurances(insuranceCheckBoxes);
    } else {
      analytics.page('PLR - RESULTADO', 'MODIFICAR OFERTA');
    }
  }, [showSimulator]);

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(
        formSchema.origin,
        formSchema.rut,
        formSchema.partnerConfigurations,
      );
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  React.useEffect(() => {
    setTimeout(() => {
      if (formSchema.isRedirect) {
        handleCurrentData({ ...formSchema, isRedirect: false });
      }
    }, 1000);
  }, [formSchema.isRedirect]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={async () => {
        setError(false);
        handleEnableLoading(true);
        const selectedInsuranceIds = Object.entries(insurancesArray)
          .filter((insurance) => {
            if (checkedInsurances[insurance[1].type]) {
              if (!insurance[1].maxPeriods && !insurance[1].minPeriods) {
                return true;
              }
              if (formSchema.requestLoanData.periods >= insurance[1].minPeriods
                && formSchema.requestLoanData.periods <= insurance[1].maxPeriods) {
                return true;
              }
            }
            return false;
          })
          .map((insurance) => insurance[1].id);
        try {
          await postSimulation<T>(
            formSchema.requestLoanData,
            formSchema.loanParams.preApproveId,
            selectedInsuranceIds,
            formSchema.PLRtoken,
          );
          if (showSimulator) {
            if (recommendedAmount.periods !== formSchema.requestLoanDataInitialState.periods
                && recommendedAmount.amount !== formSchema.requestLoanDataInitialState.amount) {
              analytics.track('Modificar Oferta', {
                initiator: 'usuario',
                dataModified: 'monto y plazo',
              });
            }
            if (recommendedAmount.periods !== formSchema.requestLoanDataInitialState.periods
               && recommendedAmount.amount === formSchema.requestLoanDataInitialState.amount) {
              analytics.track('Modificar Oferta', {
                initiator: 'usuario',
                dataModified: 'plazo',
              });
            }
            if (recommendedAmount.amount !== formSchema.requestLoanDataInitialState.amount
              && recommendedAmount.periods === formSchema.requestLoanDataInitialState.periods) {
              analytics.track('Modificar Oferta', {
                initiator: 'usuario',
                dataModified: 'monto',
              });
            }
          }
          return handleNext();
        } catch (e) {
          setError(true);
          return handleEnableLoading(false);
        }
      }}
    >
      {({
        values,
        isSubmitting,
      }) => (

        <Form>
          <LoanSimulator
            showSimulator={showSimulator}
            formSchema={values}
            requestLoan={requestLoan}
            handleLoadingSimulation={handleLoadingSimulation}
            archivePLR={archivePLR}
            handleShowSimulator={handleShowSimulator}
            isSubmitting={isSubmitting}
            amountMarks={selectedAmountMarks}
          />
          {!showSimulator && (
            <LoanProposal
              loadingSimulation={loadingSimulation}
              formSchema={values}
              recommendedAmount={recommendedAmount}
              isSubmitting={isSubmitting}
              handleShowSimulator={handleShowSimulator}
            />
          )}
          {!loadingSimulation && calculatedMarksObject && Object.keys(checkedInsurances).length > 0
            && (
              <>
                <Divider style={{ marginTop: 5 }} />
                <InsurancesSelector
                  pageCall={{ category: 'PLR - RESULTADO', name: 'MODIFICAR SEGUROS' }}
                  amount={formSchema.requestLoanData.amount}
                  periods={formSchema.requestLoanData.periods}
                  availableInsurances={insurancesArray}
                  handleSelectedAmountMarks={handleSelectedAmountMarks}
                  checkedInsurances={checkedInsurances}
                  handleSetCheckedInsurances={handleSetCheckedInsurances}
                  showSimulator={showSimulator}
                  insuranceCost={formSchema.requestLoanData.insuranceCost}
                  currency={formSchema.loanParams.currency}
                />
              </>
            )}
          {error
                && (
                  <div style={{ color: theme.palette.error.main, fontSize: 'small' }}>
                    Ha ocurrido un error, por favor contáctanos mediante whatsapp
                  </div>
                )}
          <LoanConditionsDialog
            triggerAlert={triggerAlert}
            formSchema={formSchema}
            recommendedAmount={recommendedAmount}
          />
        </Form>
      )}
    </Formik>
  );
}
