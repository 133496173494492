import React from 'react';
import {
  Fade, TextField, Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { formatRut } from 'react-rut-formatter';
import useStyles from './styles';
import { setRut } from '../../../../../../utils/helpers';
import { rutValidationSchema } from '../../../validationSchemas';
import avatar from '../../../../../../assets/avatar_excited.svg';
import useFBPixeleventTracker from '../../../../../../utils/useFBPixelEventTracker';
import ZubaleUserInputForm from '../../../../ZubaleUserInputForm';
import type { PLRFormProps } from '../../../../../../types/preloan';
import type { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import TalanaTermsAndConditions from '../../../../TalanaTermsAndConditions';
import analytics from '../../../../../../utils/analytics';

export default function RutForm<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleNext,
    handleEnableLoading,
    handleCurrentData,
  } = props;
  const classes = useStyles();
  React.useEffect(() => handleEnableLoading(false), []);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [recaptchaCheckError, setRecaptchaCheckError] = React.useState(false);
  useFBPixeleventTracker(formSchema.product, { event: 'Ingreso' });

  const [zubaleUserInput, setZubaleUserInput] = React.useState(formSchema.userInput);

  const handleZubaleUserInput = (userInput: FormSchemaV3['userInput']) => {
    setZubaleUserInput(userInput);
  };

  React.useEffect(() => {
    analytics.page('PLR - ONBOARDING', 'INGRESO RUT');
  }, []);

  const recaptchaOnChange = () => {
    analytics.track('Click Checkbox', {
      texto: 'No soy un robot',
      initiator: 'usuario',
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      validationSchema={rutValidationSchema}
      onSubmit={async (values) => {
        handleEnableLoading(true);
        setRecaptchaCheckError(false);
        let recaptchaValue;
        if (recaptchaRef.current) {
          recaptchaValue = recaptchaRef.current.getValue();
          if (!recaptchaValue) {
            setRecaptchaCheckError(true);
            return handleEnableLoading(false);
          }
        }
        handleCurrentData({
          ...values,
          captchaToken: recaptchaValue,
          origin: values.origin,
          userInput: zubaleUserInput,
          rut: formatRut(values.rut).toLocaleLowerCase(),
        });
        return handleNext();
      }}
    >
      {({
        values, handleBlur, touched, errors, setFieldValue,
      }) => (
        <Fade
          in
          {...({ timeout: 1000 })}
        >
          <Form>
            <div>
              <object
                data={avatar}
                aria-labelledby="Avatar"
                height="80px"
              />
            </div>
            <Typography
              color="text.secondary"
              variant="body2"
              align="center"
              gutterBottom
            >
              Por favor ingresa tu RUT
            </Typography>
            <TextField
              className={classes.textInput}
              size="small"
              variant="outlined"
              autoComplete="off"
              placeholder="RUT"
              inputProps={{ style: { textAlign: 'center' } }}
              id="rut"
              name="rut"
              data-pw="preloan-rut-input"
              autoFocus
              error={touched.rut && Boolean(errors.rut)}
              helperText={touched.rut ? errors.rut : ''}
              FormHelperTextProps={{ style: { textAlign: 'center' } }}
              value={values.rut}
              onChange={(e) => setRut(setFieldValue, e.target.value)}
              onBlur={(e) => {
                setRut(setFieldValue, values.rut);
                handleBlur(e);
              }}
            />
            <br />
            <br />
            <ReCAPTCHA
              onChange={recaptchaOnChange}
              style={{
                display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20,
              }}
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_PLRV2 as string}
            />
            {recaptchaCheckError && <Typography color="error" variant="caption">Queremos saber si no eres un robot </Typography>}
            <br />
            {values.origin === 'zubale'
              && (
              <ZubaleUserInputForm
                formSchema={formSchema}
                onSubmit={handleZubaleUserInput}
              />
              )}
            {values.origin === 'talana' && !values.optIn
              && <TalanaTermsAndConditions />}
          </Form>
        </Fade>
      )}

    </Formik>
  );
}
